/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { ReactNode } from "react"
import { rhythm, scale } from "../utils/typography"
import DarkModeToggle from "./DarkModeToggle"

interface IProp {
    location?: any
    title?: any
    children: ReactNode
}

class Layout extends React.Component<IProp> {
    public componentDidMount() {
        const script = document.createElement("script")

        script.async = true
        script.src = "//busuanzi.ibruce.info/busuanzi/2.3/busuanzi.pure.mini.js"

        document.body.appendChild(script)
    }

    public render() {
        const { location, title, children } = this.props
        const rootPath = `${__PATH_PREFIX__}/`
        let header

        if (location.pathname === rootPath) {
            header = (
                <h3
                    style={{
                        ...scale(1),
                        marginBottom: rhythm(1.5),
                        marginTop: 0,
                    }}
                >
                    <Link
                        style={{
                            boxShadow: "none",
                            textDecoration: "none",
                            color: "inherit",
                        }}
                        to="/"
                    >
                        {title}
                    </Link>
                </h3>
            )
        } else {
            header = (
                <h3
                    style={{
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: 0,
                        marginBottom: rhythm(-1),
                    }}
                >
                    <Link
                        style={{
                            boxShadow: "none",
                            textDecoration: "none",
                            color: "inherit",
                        }}
                        to="/"
                    >
                        {title}
                    </Link>
                </h3>
            )
        }
        return (
            <div
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: rhythm(32),
                    padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    {header}
                    <DarkModeToggle />
                </div>
                {children}
                <footer>
                    © {new Date().getFullYear()}, Built with
                    {` `}
                    <Link to="/">Ulyso</Link>
                    <span id="busuanzi_container_site_pv">
                        本站总访问量<span id="busuanzi_value_site_pv"></span>次
                    </span>
                </footer>
            </div>
        )
    }
}

export default Layout

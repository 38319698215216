import React from "react"

import useDarkMode from "use-dark-mode"

const DarkModeToggle = () => {
    const lightMode = useDarkMode(false)

    return (
        <div className="dark-mode-toggle">
            {lightMode.value === true ? (
                <button type="button" onClick={lightMode.toggle}>
                    ☾
                </button>
            ) : (
                <button type="button" onClick={lightMode.toggle}>
                    ☀
                </button>
            )}
        </div>
    )
}

export default DarkModeToggle
